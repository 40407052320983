import React, { useState } from 'react';

const MoreInfoDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button onClick={() => setIsOpen(!isOpen)}>
        More Information
      </button>
      {isOpen && (
        <div className="dropdown-content">
          <p className='drop-down'>
          To initiate the import request, a higher-capacity RDP configuration is required. Please ensure a minimum of 20GB RAM and 400GB SSD storage for optimal performance with the selected desktop. <strong className='drop-strong'>All application data files are securely protected and accessible exclusively within the RDP environment.</strong>
          </p>
        </div>
      )}
    </div>
  );
};

export default MoreInfoDropdown;
