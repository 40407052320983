import React from 'react';
import '../Styles/Header.css';

const Header = () => (
  <header>

<div className="Head-tab">
  <h1 id="page-logo" className='Head-style'>Your Remote Access Monitoring results is ready...</h1>
</div>
  </header>
);

export default Header;
