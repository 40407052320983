import React from 'react';
import MoreInfoDropdown from './MoreInfoDropdown';

const ExportErrorPage = () => (
  <div>
    <h1 className='import-er'>IMPORT ERROR !!!</h1>

    <p className='App-file'>Application File too large for RDP Monitoring Request.</p>

    <MoreInfoDropdown />
  </div>
);

export default ExportErrorPage;
