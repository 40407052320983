import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const BruteForceForm = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  // Required device information
  const requiredInfo = {
    deviceName: 'DESKTOP-914TRMV',
    processor: 'Intel(R) Core(TM) i5-7500T CPU @ 2.70GHz   2.71 GHz',
    ram: '16.0 GB',
    deviceId: '0882F985-E5F8-47E1-9555-3DA064D04361',
    productId: '00330-51007-70532-AAOEM',
    systemType: '64-bit Operating System, x64-based processor' // Adding systemType
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Extract form values
    const deviceName = e.target[0].value;
    const processor = e.target[1].value;
    const ram = e.target[2].value;
    const deviceId = e.target[3].value;
    const productId = e.target[4].value;
    const systemType = e.target[5].value;

    // Check if entered information matches required info
    if (
      deviceName === requiredInfo.deviceName &&
      processor === requiredInfo.processor &&
      ram === requiredInfo.ram &&
      deviceId === requiredInfo.deviceId &&
      productId === requiredInfo.productId &&
      systemType === requiredInfo.systemType
    ) {
      navigate('/error'); // Navigate to ExportErrorPage if valid
    } else {
      setErrorMessage('Input field error: please add correct desktop ID info.');
    }
  };

  return (
    <div className="form-container">
      <h4 className='brute-bypass'>Brute Force Bypass Request</h4>

      <h5>Please provide the Desktop Serial Number and Memory specifications for Remote Monitor Request.</h5>

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <form onSubmit={handleSubmit} className="grid-form">
        <label>
          Device Name
          <input type="text" placeholder="Device Name" required />
        </label>
        <label>
          Processor Intel(R)
          <input type="text" placeholder="Processor Intel(R)" required />
        </label>
        <label>
          RAM
          <input type="text" placeholder="RAM" required />
        </label>
        <label>
          Device ID
          <input type="text" placeholder="Device ID" required />
        </label>
        <label>
          Product Type
          <input type="text" placeholder="Product Type" required />
        </label>
        <label>
          System Type
          <input type="text" placeholder="System Type" required />
        </label>
        <button type="submit" className="export-button">Export to RDP</button>
      </form>
    </div>
  );
};

export default BruteForceForm;
