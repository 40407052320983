import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import TargetInfo from './Components/TargetInfo';
import ApplicationsGrid from './Components/ApplicationsGrid';
import BruteForceForm from './Components/BruteForceForm';
import ExportErrorPage from './Components/ExportErrorPage';

function App() {
  const location = useLocation();
  const hideHeaderOnErrorPage = location.pathname === '/error';

  return (
    <div className="App">
      {!hideHeaderOnErrorPage && <Header />}
      <Routes>
        <Route path="/" element={
          <>
            <TargetInfo />
            <ApplicationsGrid />
            <BruteForceForm />
          </>
        } />
        <Route path="/error" element={<ExportErrorPage />} />
      </Routes>
    </div>
  );
}


function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
