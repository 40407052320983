import React from 'react';

const TargetInfo = () => (
  <div>
    <p className='info-tab'>
      Target Device 
      <svg
        className='Equal-sign'
        width="20"
        height="20"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="rgb(237, 2, 2)" d="M3 10h18v4H3zm0 6h18v4H3z" />
      </svg>
      <strong className='info-strong'>Android Galaxy S23
      </strong>
    </p>

    <p className='info-tab'>
      Target Phone-Number
      
      <svg
        className='Equal-sign'
        width="20"
        height="20"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="rgb(237, 2, 2)" d="M3 10h18v4H3zm0 6h18v4H3z" />
      </svg>
      
       <strong className='info-strong'>+1(323)406-0803</strong>
    </p>
  </div>
);

export default TargetInfo;
