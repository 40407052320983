import React from 'react';

const ApplicationsGrid = () => (
  <div>
    <h3 className='Target-tab'>Targeted Applications <strong className='Target-strong'>COMPLETE (for export)</strong> </h3>

    <div className="applications-grid">
      <div className="app-box">
      <img width="20" height="20" src="https://img.icons8.com/fluency/48/whatsapp.png" alt="whatsapp"/>
        Whatsapp</div>
      <div 
      
      className="app-box">
        <img width="20" height="20" src="https://img.icons8.com/color/48/topic--v1.png" alt="topic--v1"/>
        Text Message</div>


      <div className="app-box">
      <img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/instagram-logo.png" alt="instagram-logo"/>
        Instagram</div>

      <div className="app-box">
      <img width="20" height="20" src="https://img.icons8.com/color/48/facebook.png" alt="facebook"/>
        Facebook</div>


      <div className="app-box">
      <img width="20" height="20" src="https://img.icons8.com/3d-fluency/94/phone-disconnected.png" alt="phone-disconnected"/>
        Call Data History</div>

        <div className="app-box">
        <img width="20" height="20" src="https://img.icons8.com/cotton/64/gallery.png" alt="gallery"/>
        Images/Video</div>
        
    </div>
  </div>
);

export default ApplicationsGrid;
